import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Ecp extends Vue {
  get documentsMap(){
    return {}
  }
  protected documentsForSigns: number[] = []
  protected showEcpModal: boolean = false

  protected singleEcp(id){
    this.documentsForSigns = [this.documentsMap[id]]
    this.openEcpModal()
  }

  protected openEcpModal() {
    this.showEcpModal = true
  }

  protected filesSigned(ids: number[]){
    ids.forEach(id => (id in this.documentsMap && this.documentsMap[id].signsCount++))
    this.documentsForSigns = []
  }
}
