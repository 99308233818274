



































































































































































// Core
import {Component, Mixins} from 'vue-property-decorator'
import Debounce from '@/decorators/Debounce'

// Mixins
import Documents from '@/mixins/Documents'
import Ecp from '@/mixins/Ecp'

// Services
import http from '@/http'

// Store
import {documentsNamespace} from '@store/documents'
import {userNamespace} from '@store/user'

// Types
import {DocumentsActionTypes} from '@store/documents/Types'

// Interfaces
import {IDocumentType, IDocumentTypes} from '@store/documents/Interface'

// Styles
import './library.scss'

interface IFilterParams {
  category: IDocumentTypes | null
  markId: number | null
  insidePage: number
  search: string
}

@Component({
  name: 'Library',
  components: {
    'v-dialog-add-document': () => import('@/components/dialogs/DialogAddDocument.vue'),
    'v-document': () => import('./components/Document.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
    'v-ecp-form-modal': () => import('@/views/library/components/EcpFormModal.vue'),
    'v-ecp-icon': () => import('@/views/library/components/EcpIcon.vue'),
  },
})
export default class LibraryComponent extends Mixins(Documents, Ecp) {
  @userNamespace.State('selectedProject')
  private selectedProject!: number

  @documentsNamespace.State('documentsAll')
  private documents!: IDocumentType

  @documentsNamespace.State('documentTypes')
  private categoryOptions!: IDocumentTypes[]

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENT_READ)
  private documentRead!: ({documentId, documentIds}) => Promise<void>

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENTS_ALL)
  private fetchDocumentsAll!: (params) => Promise<void>

  private isIndeterminate: boolean = false
  private checkAll: boolean = false
  private checkedDocuments: number[] = []


  get documentsMap(){
    if(!this.documents?.documentList) return {}
    return this.documents?.documentList.reduce((sum, document) => {
      sum[document.id] = document
      return sum
    }, {})
  }

  private massEcp(){
    this.documentsForSigns = this.checkedDocuments.map(id => this.documentsMap[id])
    this.openEcpModal()
  }

  private visibleDialogAddDocument: boolean = false
  private filterParams: IFilterParams = {
    category: null,
    markId: null,
    insidePage: 1,
    search: '',
  }

  private get allDocs(): number[] {
    return this.documents.documentList.map(doc => doc.id)
  }

  private loading: boolean = true



  mounted() {
    this.changePage()
  }

  private handleSuccess() {
    this.$nextTick(() => {
      this.changePage()
    })
  }

  private async changePage() {
    this.loading = true
    await this.fetchDocumentsAll({projectId: this.selectedProject, filter: this.filterParams})
    this.loading = false
  }

  private changeCategory() {
    this.filterParams.insidePage = 1
    this.changePage()
  }

  @Debounce(1000)
  private changeSearch() {
    if (this.filterParams.search.length >= 3 || this.filterParams.search.length === 0) {
      this.changePage()
    }
  }

  private handleCheckAllChange(value) {
    this.checkedDocuments = value ? this.allDocs : [];
    this.isIndeterminate = false;
    this.checkAll = value
  }

  private handleCheckedDocumentsChange(value) {
    const checkedCount = value.length;
    this.checkAll = checkedCount === this.allDocs.length;
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.allDocs.length;
  }

  private handleMassReadDocumets() {
    http.post('/documents/mass-read', {documents: this.checkedDocumentsNew,})
      .then(() => {
        this.changePage()
        this.$successNotify()
      })
      .catch(error => this.$errorNotify(error.response?.data?.message))
  }

  private downloadDocuments() {
    this.loading = true
    http.post('/documents/mass-download', {documents: this.checkedDocuments})
      .then(r => {
        this.massDownloadDocuments(r.data.link)
      })
      .catch(error => this.$errorNotify(error.response?.data?.message))
      .finally(() =>  this.loading = false)
  }

  private get checkedDocumentsNew(): number[] {
    return this.documents.documentList
      .filter((doc) => {
        let result = false
        this.checkedDocuments.forEach(checkDocId => {
          if (doc.id === checkDocId && doc.isNew) {
            result = true
          }
        })

        return result
      })
      .map(doc => doc.id)
  }
}
