import {Component, Vue} from 'vue-property-decorator'
import http from '@/http'
import {userNamespace} from '@store/user'
import {IUser} from '@store/user/Interface';

@Component
export default class Documents extends Vue {
  @userNamespace.State('user')
  protected user!: IUser

  protected viewDocument(link: string) {
    window.open(link, '_blank')
  }

  protected async downloadDocument(link: string) {
    try{
      const $a = document.createElement('a')
      document.body.appendChild($a)
      $a.href = link || ''

      http.post('/logs', {user_id:  this.user?.id, action: 'document', path:link, status:true}).finally(() => {
        $a.click()
        $a.remove()
      })

    } catch (error) {
      this.$errorNotify(error.response?.data?.message)
      await http.post('/logs', {user_id:  this.user?.id, action: 'document', path: link, status:false})
    }
  }

  protected async massDownloadDocuments(link: string) {
    try{
      const $a = document.createElement('a')
      document.body.appendChild($a)

      if (link.includes('http://') || link.includes('https://')) {
        $a.href = link
      } else {
        $a.href = window.location.origin + '/api/' + link
      }

      http.post('/logs', {user_id: this.user?.id, action: 'document', path:link, status:true}).finally(() => {
        $a.click()
        $a.remove()
      })

    } catch (error) {
      this.$errorNotify(error.response?.data?.message)
      await http.post('/logs', {user_id:  this.user?.id, action: 'document', path: link, status:false})
    }
  }

  protected async downloadDocumentById(documentId){
    try{
      const r = await http.get(`/documents/${documentId}/download`)
      await this.downloadDocument(r.data.link)
    } catch (error){
      this.$errorNotify(error.response?.data?.message)
    }
  }
}
